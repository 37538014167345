
/*CARTAS*/
.contenedorCard{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 25px;
    color: white;

}

.contenedorCard div{
    color: white !important;
}
.bigIcon{
    margin: auto;
    font-size: 60px !important;
}

.bigIcon span{
    margin: auto;
    font-size: 40px !important;
}

.dataCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dataCardText{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: end;
    margin-right: 7px;
}

.dataCardText div, .contenidoCard {
    margin: 0 !important;
    padding: 0 !important;
}


.contenidoCard .dataCard{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

.dataCardText{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.bigIcon{
    margin: auto;
    font-size: 50px !important;
}

.bigIcon span{
    margin: auto;
    font-size: 40px !important;
}