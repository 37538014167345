.cartaInvididuales {
    padding: 0 !important;
    border: 1px solid #8bc34a;
}
.cartaInvididualesCotizacion {
  padding: 0 !important;
  border: 1px solid #1976d2;
}

.arrowGreen {
    color: #8bc34a !important;
}
.arrowBlue {
  color: #1976d2 !important;
}

.textIndividuales {
    text-align: left;
    margin-left: 5px;
}
.textIndividualesDiv {
    padding: 5px;
}

.dataCardOptions {
    display: flex;
    flex-direction: row;
}
.dataCardSell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dataCardSellAction {
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    /*margin-right: 7px;*/
}

.css-ja5taz-MuiTooltip-tooltip {
    color: #fff !important;
}

.MuiTooltip-tooltip {
    color: #fff !important;
}

.lista-detalle-venta table {
    width: auto;
    text-align: start;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
    margin-top: auto !important;
}

.css-1pb7v8v-MuiTableRow-root td,
.css-1pb7v8v-MuiTableRow-root th {
    padding: 3px !important;
}

.css-177c4i4-MuiTableCell-root.MuiTableCell-head {
    background: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/background-img.png")
        no-repeat center center fixed !important;
}
