.Auth-form-container {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: var(--color-fondo-claro);
}

.Auth-form-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding-left: 12%;
    padding-right: 12%;
}

.Auth-form-title {
    font-size: 19px;
    color: var(--color-font-oscuro);
    font-weight: 800;
}

.btn-login {
  width: 100%;
  text-align: center;
}

label {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-font-oscuro);
}

.form-group{
  margin-bottom: 0 !important;
}

body{
  height: auto !important;
}

@media screen and (max-width: 1200px) {
  .Auth-form{
    margin: 5%;
  }
}
