/*=========
 * General
 ==========*/
/* fallback */
@font-face {
    font-family: "Material Symbols Outlined";
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v57/kJEhBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oFsI.woff2)
        format("woff2");
}

/* Change the white to any color */
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select:-webkit-autofill,
input:-webkit-autofill {
    -webkit-text-fill-color: var(--color-font-oscuro) !important;
}

.material-symbols-outlined {
    font-family: "Material Symbols Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
    --color-morado: #7785c9 !important;
    --color-morado-claro: #929dd4 !important;
    --color-fondo-claro: #f2f2f7 !important;
    --color-font-azul-oscuro: #30577a !important;
    --color-font-oscuro: #666666 !important;
    --color-verde-musgo: #a7bc85 !important;

    --border-radius-1: 25px !important;
    --border-radius-2: 15px !important;
    --border-radius-3: 6px !important;

    --card-padding: 1.8rem !important;
    --padding-1: 1.2rem !important;
    --font-super-big: 30px !important;
    --font-big: 20px !important;
    --font-medium: 15px !important;
    --font-small: 10px !important;
}

/* Fuentes */
@font-face {
    font-family: Kohi-regular;
    src: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/KohinoorBangla-Regular.otf");
}

@font-face {
    font-family: Kohi-semibold;
    src: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/KohinoorBangla-Semibold.otf");
}

@font-face {
    font-family: Alef-Bold;
    src: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/Alef-Bold.ttf");
}

@font-face {
    font-family: Alef-Regular;
    src: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/Alef-Regular.ttf");
}

div {
    color: var(--color-font-oscuro) !important;
    font-family: Kohi-semibold !important;
}

label {
    margin-bottom: 0 !important;
}

hr {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

main .container-page {
    padding: 24px;
}

.conteiner-cargar hr {
    height: 5px !important;
    background: #929dd4 !important;
    margin: 20px 0 !important;
    box-shadow: 0px 0px 2px 1px rgba(204, 204, 204, 1) !important;
}

h1 {
    font-size: var(--font-super-big) !important;
}

h5 {
    font-size: var(--font-big) !important;
}

/* Reglas CSS */

html,
body {
    size: 100%;
    height: 100%;
}

html {
    background: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/background-img.png")
        no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}

body {
    background-color: transparent !important;
}

/* Estilos formulario de enrolamiento */
.contenedor-formulario {
    padding: 70px 10px 70px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

#accordion-seguros {
    display: flex !important;
    max-width: 90% !important;
    min-width: 90% !important;
    min-height: 70% !important;
    box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%) !important;
    border-radius: 20px 20px 0 0 !important;
}

.App .accordion-button:after {
    background-image: none !important;
}

.accordion-button {
    background-color: var(--color-fondo-claro) !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    height: 50px !important;
    font-size: 18px !important;
    color: inherit !important;
    pointer-events: none !important;
    border-radius: var(--border-radius-2) !important;
    box-shadow: none !important;
}

.titulos-pasos {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    vertical-align: auto !important;
    height: 50px !important;
    font-size: 18px !important;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    border-radius: 20px 20px 0 0 !important;
}

.activo h2 {
    color: var(--color-fondo-claro) !important;
    background-color: var(--color-morado) !important;
}

.collapse {
    background-color: white !important;
    border-radius: 0 0 20px 20px !important;
    flex: 1 !important;
    min-height: 430px !important;
}

.card-header {
    width: 100% !important;
    color: var(--color-morado-claro) !important;
    background-color: var(--color-fondo-claro) !important;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%) !important;
    margin: 0 !important;
    padding: 5px !important;
}

.card-header h2 {
    margin: 0 !important;
}

.cuerpo-info-seguro {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 10px !important;
}

.cuerpo-info-seguro strong {
    margin-top: 25px !important;
    margin-bottom: 15px !important;
    color: var(--color-font-azul-oscuro) !important;
    font-size: var(--font-big) !important;
}

.info-seguro {
    font-size: 16px !important;
    /*text-align: justify;*/
}

.cartas-seguros {
    display: flex !important;
    flex-wrap: initial !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-bottom: 8px !important;
    margin-top: 15px !important;
    background-color: var(--color-fondo-claro) !important;
    border-radius: var(--border-radius-2) !important;
    min-height: 515px !important;
}

.cartas-seguros > div {
    margin: 10px !important;
}

.contenedor-tabla-valores {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.tabs {
    display: flex !important;
    justify-content: center !important;
    flex-direction: row !important;
    max-width: 90% !important;
    min-width: 90% !important;
    box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%) !important;
    background-color: var(--color-fondo-claro) !important;
    border-radius: 20px 20px 0 0 !important;
}

.activo {
    color: var(--color-fondo-claro) !important;
    background-color: var(--color-morado) !important;
    box-shadow: 0 3px 13px 1px rgb(0 0 0 / 9%) !important;
    z-index: 1 !important;
    -webkit-box-shadow: 10px 10px 15px 1px rgba(146, 157, 212, 0.24) !important;
    -moz-box-shadow: 10px 10px 15px 1px rgba(146, 157, 212, 0.24) !important;
    box-shadow: 10px 10px 15px 1px rgba(146, 157, 212, 0.24) !important;
    border-radius: 20px 20px 0 0 !important;
}

#heading_2,
#heading_1,
#heading_0 {
    border-radius: 20px 20px 0 0 !important;
}

.paso-listo {
    background-color: rgba(150, 205, 181) !important;
}

#enviarFormulario {
    margin-top: 10px !important;
}

.form-check-input-reverse {
    margin-left: 10px !important;
}

.contenedor-submit {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.contenedor-submit .siguiente-info {
    margin: 25px !important;
}

.contenedor-add-carga {
    margin-top: 10px !important;
    margin-bottom: 40px !important;
}

.card-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding: 25px !important;
}

#collapseOne .card-body {
    padding: 0 !important;
}

.row {
    padding: 5px !important;
}

/*.row .col-md-3 {
    margin-top: 20px !important;
    margin-bottom: 5px !important;
}*/

#exampleModal {
    padding-right: 0px !important;
}

.form-error:focus {
    border-color: rgba(255, 0, 0, 0.603) !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.plan {
    background-color: #ffffff !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 10px !important;
    width: 305px !important;
}

.titulo-plan {
    display: flex !important;
    background-color: var(--color-font-azul-oscuro) !important;
    color: white !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: var(--font-big) !important;
    font-weight: bold !important;
    padding: 5px 0 5px 0 !important;
    border-radius: 10px 10px 0 0 !important;
}

.cuerpo-plan {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px 20px 10px 20px !important;
}

.valor-plan {
    color: var(--color-font-azul-oscuro) !important;
    margin-top: 3px !important;
    font-size: var(--font-super-big) !important;
    font-family: Kohi-regular !important;
}

.valores-carga-plan {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 5px !important;
    width: 100% !important;
    align-content: center !important;
    justify-content: center !important;
    text-align: center !important;
    gap: 2px !important;
}

.valores-carga-plan div span {
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    justify-content: space-between !important;
    gap: 3px !important;
    vertical-align: top !important;
}

.botones-plan {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 15px !important;
    margin-top: 10px !important;
}

.botones-plan .button-plan:hover {
    background-color: var(--color-font-azul-oscuro) !important;
}

.botones-plan .btn-dark-green:hover {
    background-color: var(--color-verde-musgo) !important;
}

.accionesPlan {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 5px !important;
}

.btn-card {
    width: 100% !important;
    min-width: 100% !important;
}

.button-plan {
    border: none !important;
    background-color: var(--color-font-azul-oscuro) !important;
    border-radius: 20px !important;
    display: inline-block !important;
    cursor: pointer !important;
    color: hsl(0, 0%, 100%) !important;
    font-family: Alef-Bold !important;
    font-size: 15px !important;
    padding: 3px 12px !important;
    text-decoration: none !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    text-align: center !important;
    user-select: none !important;
    line-height: 1.5 !important;
}

.btn-principal {
    background-color: var(--color-morado) !important;
    border-radius: 10px !important;
    display: inline-block !important;
    cursor: pointer !important;
    color: #ffffff !important;
    font-family: Arial !important;
    font-size: 20px !important;
    font-weight: bold !important;
    padding: 6px 100px !important;
    text-decoration: none !important;
    border: aliceblue !important;
}

.btn-principal:active {
    position: relative !important;
    top: 1px !important;
}

.btn-dark-green {
    background-color: var(--color-verde-musgo) !important;
    color: var(--color-font-azul-oscuro) !important;
}

.button-plan:hover {
    text-decoration: none !important;
    color: var(--color-fondo-claro) !important;
}

.button-plan:active {
    position: relative !important;
    top: 1px !important;
}

.btn-add-carga {
    background-color: var(--color-verde-musgo) !important;
    border-radius: 10px !important;
    display: inline-block !important;
    cursor: pointer !important;
    color: #ffffff !important;
    font-family: Arial !important;
    font-size: 17px !important;
    font-weight: bold !important;
    padding: 10px 40px !important;
    text-decoration: none !important;
}

.btn-add-carga:active {
    position: relative !important;
    top: 1px !important;
}

.contenedor-submit span {
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    justify-content: center !important;
    gap: 3px !important;
    vertical-align: top !important;
}

.contenedor-submit .btn-principal:hover {
    background-color: var(--color-morado) !important;
    color: var(--color-fondo-claro) !important;
}

.conteiner-cargar .row .col-md-3 {
    margin-top: 5px !important;
}

.proceso-pago {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
    align-items: center !important;
    vertical-align: inherit !important;
}

.contenedor-pago {
    font-family: Alef-Regular !important;
    font-size: var(--font-super-big);
}

.proceso-pago label {
    font-size: var(--font-big) !important;
}

.label-color-blue {
    color: var(--color-font-azul-oscuro) !important;
}

.label-color-purple {
    color: var(--color-morado) !important;
}

/* SPINNER */
.contenedor-spinner {
    padding: 30px;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 6px;
    left: 74px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--color-font-azul-oscuro);
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.mhaite_spinner {
    width: 100%;
}

.swal2-close {
    background-color: unset !important;
}

.swal2-styled {
    background-color: var(--color-morado) !important;
}

.swal2-cancel {
    background-color: #6e7881 !important;
}

.mas-info {
    background-image: url("https://seguros.ticmedical.cl/archivos-publicos/archivos/background-img.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    text-align: center !important;
    vertical-align: center !important;
    color: white !important;
    padding: 0.5em 1em 0.5em !important;
    border-radius: 5px 5px 0 0 !important;
}

.text-danger-seguro {
    color: #da4857 !important;
    opacity: 0.9 !important;
}

.logo_mhaite_pago {
    width: 35%;
}

.mostrar-pago-success {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.mostrar-pago-success label {
    padding-left: 10% !important;
    padding-right: 10% !important;
}

.titulo-paso {
    color: var(--color-font-azul-oscuro) !important;
}

.dot {
    font-size: larger !important;
    color: var(--color-font-azul-oscuro) !important;
}

.requerido {
    font-size: small !important;
    color: rgba(0, 0, 0, 0.767) !important;
}

.requerido-texto {
    font-size: var(--font-medium) !important;
}

.plan-activo .titulo-plan {
    color: var(--color-font-azul-oscuro) !important;
    background-color: white !important;
}

.plan-activo {
    background-color: var(--color-font-azul-oscuro) !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 19px -5px rgba(0, 0, 0, 1) !important;
    -webkit-box-shadow: 0px 0px 19px -5px rgba(0, 0, 0, 1) !important;
    -moz-box-shadow: 0px 0px 19px -5px rgba(0, 0, 0, 1) !important;
}

.plan-activo .cuerpo-plan {
    color: white !important;
}

.plan-activo .cuerpo-plan .botones-plan button:first-of-type {
    background-color: white !important;
    color: var(--color-font-azul-oscuro) !important;
}

.plan-activo .cuerpo-plan .valor-plan {
    color: white !important;
}

.plan-activo .cuerpo-plan .valores-carga-plan span {
    color: white !important;
}

.plan-activo .cuerpo-plan .botones-plan label {
    color: white !important;
}

.go-back {
    cursor: pointer !important;
}

.contenedor-marcas {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 45px !important;
    color: var(--color-font-azul-oscuro) !important;
}

.marcas {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px;
}

.marcas img {
    width: auto !important;
    height: 70px !important;
}

.marcas .logo_bci {
    height: 55px !important;
}

.small-letter {
    font-size: 13px !important;
    margin-top: -10px !important;
}

.cobertura-plan {
    margin-top: 15px !important;
}

.flip-card {
    background-color: transparent;
    width: 305px !important;
    height: 510px !important;
    perspective: 1000px !important;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px !important;
}

.girar-flip-card {
    /*, .flip-card:hover .flip-card-inner {*/
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}

.cuerpo-plan-loading {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 0px !important;
}

.valor-plan-loading {
    width: 100% !important;
    color: var(--color-font-azul-oscuro) !important;
    margin-top: 10px !important;
    font-size: var(--font-super-big) !important;
    font-family: Kohi-regular !important;
}

.loading {
    width: 100% !important;
}

.skeleton {
    padding: 0 !important;
}

.loadingTitulo:empty {
    border-radius: 10px 10px 0 0 !important;
    background-color: var(--color-font-azul-oscuro) !important;
    width: 100%;
    height: 40px;
    background: linear-gradient(
            0.25turn,
            transparent,
            rgba(255, 255, 255, 0.76),
            transparent
        ),
        linear-gradient(
            var(--color-font-azul-oscuro),
            var(--color-font-azul-oscuro)
        );
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.loadingValor:empty {
    background-color: white;
    width: 100%;
    height: 40px;
    background: linear-gradient(
            0.25turn,
            #64839e00,
            var(--color-font-azul-oscuro),
            #64839e00
        ),
        linear-gradient(white, white);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.loadingTexto:empty {
    background-color: white;
    width: 100%;
    height: 20px;
    background: linear-gradient(
            0.25turn,
            #66666600,
            var(--color-font-oscuro),
            #66666600
        ),
        linear-gradient(white, white);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
    margin-top: 30px;
    margin-bottom: 10px;
}

.loadingContenido:empty {
    background-color: white;
    width: 100%;
    height: 200px;
    background: linear-gradient(
            0.25turn,
            #66666600,
            var(--color-font-oscuro),
            #66666600
        ),
        linear-gradient(white, white);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.loadingBtnVerde:empty {
    height: 30px;
    background-color: white;
    width: 100%;
    height: 30px;
    background: linear-gradient(
            0.25turn,
            #a7bc8500,
            var(--color-verde-musgo),
            #a7bc8500
        ),
        linear-gradient(white, white);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.loadingBtnAzul:empty {
    margin-top: 10px;
    margin-bottom: 5px;
    background-color: white;
    width: 100%;
    height: 30px;
    background: linear-gradient(
            0.25turn,
            #64839e00,
            var(--color-font-azul-oscuro),
            #64839e00
        ),
        linear-gradient(white, white);
    background-repeat: no-repeat;
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px;
    animation: loading 1.5s infinite;
}

.contenedorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@keyframes loading {
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}

@keyframes shine {
    to {
        background-position: 100% 0, /* move highlight to right */ 0 0;
    }
}

@media screen and (max-width: 1200px) {
    div[class=MuiBox-root] {
        margin-right: 15px;
        height: 100%;
    }
}

.div-contenedor {
    height: 100%;
}
div[class=MuiBox-root] {    
    height: 100%;
}

body > :first-child{
    height: 100%;
}

body > :first-child > div:first-child{
    height: 100%;
}

.detalleCargaTexto{
    font-size:  var(--font-medium) !important;
}
.textoCargaTitulo{
    font-size:  20px !important;
}
.detalleCargaWait{
    color: #1976d2 !important;
    margin-bottom: 6px !important;
}
.detalleCargaDone{
    color: #2e7d32 !important;
    margin-bottom: 3px !important;
}
/*======================
 * Componentes de React 
 =======================*/
