label {
    font-size: 16px !important; 
}

.btn-acciones{
    display: flex;
    flex-direction: column;
}

.InfoIcon{
    margin-left: 2px;
    color: var(--color-font-azul-oscuro);
    cursor: pointer;
}

/*.swal2-html-container{
    height: 240px !important;
}*/

/*.ordenar-row div{
    flex: auto !important;
    max-width: 100% !important;
}*/

.formularioSale{
    display: flex;
    flex-wrap: wrap;
}


.conteiner-cargar .row .col-md-3 {
    margin-top: 5px !important;
}

.conteiner-cargar hr {
    height: 5px !important;
    background: #929dd4 !important;
    margin: 20px 0 !important;
    box-shadow: 0px 0px 2px 1px rgba(204, 204, 204, 1) !important;
}

.conteiner-form-extended{
    display: flex;
    flex-direction: column;
}

.conteiner-form-extended .row div {
    padding-left: 5px !important;
}

.titulo-venta{
    font-size: 25px !important;
}


.iframe-div{
    width: 100%;
    height: 100% !important;
    margin: 0; 
}

.iframe-div iframe{
    display: block;       /* iframes are inline by default */
    background: #000;
    border: none;         /* Reset default border */
    /*height: 41vw;*/        /* Viewport-relative units */
    width: 100vw;
}


.custom-file-upload {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border-radius: 5px;
  }
  
  .file-input {
    display: none;
  }

  .swal-overlay {
    z-index: 2000 !important;
  }
  
  /* Ajustar el z-index del modal según sea necesario */
  .MuiDialog-root {
    z-index: 1000 !important;
  }


