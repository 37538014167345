.boton{
  border: 0px !important;
  background-color: #896e9f !important;
  color: #ffffff !important;
}
.container{
  border-radius: 15px;
}
.div_principal{
  margin-bottom: 0px;
}
.color_text{
  color: #652d90;
  font-size: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
.div_button{
  padding: 15px;
}
.swalButton div button{
  background-color: #8b6fac !important;
  color: #ffffff !important
}
.textVolver a{
  color: #652d90 !important; 
  cursor: pointer;    
  text-decoration-line: underline !important;
}
.inputCorreo{
  padding-top: 10px;
}

.RutInput input{
  border-radius: 10px!important;
  margin-top: 10px;
}

.test{
  border-radius: 12.5px !important;
}